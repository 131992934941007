#unsupported-browser {
  main {
    padding: 20px 30px 50px 30px;
    margin: 0 auto;
    @media (min-width: 601px) {
      width: 601px;
    }
    @media screen and (min-width: 993px) {
      width: 993px;
    }
  }
}
