.describer-card {
  img {
    width: 50px;
    border-radius: 50%;
  }

  .rating-desc {
    button {
      color: grey;
      background-color: white;
      border: none;
      outline: none;
      margin: 0;
      padding: 0 2px 0 0;
      cursor: pointer;
      pointer-events: none;
    }
  }

  .contribution-bars {
    margin-top: 10px;
  }

  .contribution-bar {
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div:first-child {
      width: 30%;
      text-align: right;
      padding-right: 10px;
      white-space: nowrap; /* Prevent text wrapping */
      overflow: hidden; /* Hide overflow */
      text-overflow: ellipsis; /* Show ellipsis for overflowed text */
    }

    div:last-child {
      width: 70%;
      display: flex;
      align-items: center;

      div:first-child {
        background-color: #4CAF50;
        height: 10px;
        margin-right: 10px;
        padding: 0;
      }
    }
  }

  @media screen and (min-width: 601px) {
    // top: 569px;
  }
}
