#feedback-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);
  #feedback-popup-contents {
    position: fixed;
    z-index: 9999;
    width: 350px;
    // height: 200px;
    top: 50%;
    transform: translateY(-50%);
    left: calc(50% - 175px);
    border: 1px solid #3f51b5;
    background-color: white;
    padding-bottom: 16px;
    a {
      color: #fff;
    }
    i {
      position: absolute;
      top: 4px;
      right: 4px;
      font-size: 20px;
      cursor: pointer;
    }
    h2 {
      margin: 0;
      padding: 8px 0;
      background-color: #3f51b5;
      text-align: center;
    }
    p {
      margin: 16px;
      color: grey;
      font-size: 14px;
      // padding: 16px;
    }
    form {
      padding: 0 16px;
      color: black;
    }
    /*
      Ratings Stars
      (with as little code as possible)
      */
    .feedback {
      // unicode-bidi: bidi-override;
      width: 100%;
      direction: rtl;
      // margin: auto 0;
      text-align: center;
    }
    .feedback > button {
      color: grey;
      display: inline-block;
      position: relative;
      padding: 0 1px;
      font-size: 24px;
      width: 1.1em;
      background-color: white;
      border: none;
      outline: none;
      cursor: pointer;
    }
    .feedback > button:hover,
    .feedback > button:hover ~ button {
      color: transparent;
    }
    .feedback > button:hover:before,
    .feedback > button:hover ~ button:before {
      content: '\2605';
      //  position: absolute;
      //  left: 0;
      color: gold;
    }
  }
}
