@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');
.ydx-body {
  background-color: #383838;
  padding-top: 10px;
  padding-bottom: 40px;
  margin: 0;
  line-height: 1.6;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Fira Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'EB Garamond', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ydx-html {
  scroll-behavior: smooth;
}

.ydx-code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ydx-button {
  box-shadow: 0px 0px 4px 1px rgb(206, 246, 240);
}

.ydx-button-lable {
  margin-left: 10px;
}

.ydx-button:hover {
  box-shadow: 0px 1px 4px 2px bisque, 0px 1px 2px 2px white;
}

:root {
  --primary-color: #3f51b5;
  --extended-color: #9c27b0;
  --inline-color: #ffeb3b;
  --success-color: #19887e;
  --inactive-color: #cdcdcd;
}

/* remove box shadow for Toasts */
.Toastify__close-button {
  box-shadow: none;
}
.Toastify__close-button:hover {
  box-shadow: none;
}

/* italics for placeholders in text area */
::-webkit-input-placeholder {
  font-style: italic;
}
:-moz-placeholder {
  font-style: italic;
}
::-moz-placeholder {
  font-style: italic;
}
:-ms-input-placeholder {
  font-style: italic;
}
