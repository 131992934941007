#home {
  /* Firefox */
  min-height: -moz-calc(100vh - 54px);
  /* WebKit */
  min-height: -webkit-calc(100vh - 54px);
  /* Opera */
  min-height: -o-calc(100vh - 54px);
  /* Standard */
  min-height: calc(100vh - 54px);
}
