.spinner {
  position: relative;
  float: left;
  height: 300px;
  display: block;
  left: 50%;
}

.spinner-loader {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.loading-text {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  margin-top: 30px;
  margin-left: -30px;
  font-size: 12px;
}
