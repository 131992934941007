@import '../css/index.css';
/* rectangular container */
.home-container {
  /* height: 100vh; */
  position: relative;
  width: 100%;
  border: solid 3px;
  border-radius: 10px;
  margin-top: 0.5rem;
  margin-bottom: 2%;
}

/* spinner */
.spinner-div {
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2000; /* Specify a stack order in case you're using a different order for other elements
  Bootstrap Modal z-index is 1060.. so use any number >1060 to show the spinner on top of the Modal */
  cursor: pointer; /* Add a pointer on hover */
}

/* primary color */
.primary-btn-color {
  background-color: var(--primary-color);
}

/* horizontal line color */
.ydx-hr {
  background-color: #ffffff;
}

/* pushing the timeline div a bit higher */
.div-below-hr {
  margin-top: -10px;
  margin-bottom: 2.2rem;
}

/* dialog timeline */
/* label of dialog timeline */
.dialog-timeline-text {
  font-size: large;
  height: 120%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* white div - dialog timeline */
.timeline-div {
  height: 20px;
  background-color: white;
  outline: 1.8px #e4a48b solid;
  border-radius: 10px;
  position: relative;
}
/* draggable component - div - dialog timeline */
.draggable-div {
  position: relative;
}
/* red draggable div progress bar - dialog timeline */
.progress-bar-div {
  /* float: 'left'; */
  cursor: ew-resize;
  width: 2px;
  height: 45px;
  background-color: red;
  position: absolute;
  z-index: 1;
  margin-top: -14px;
}
/* time of the progress-bar draggable */
.progress-bar-time {
  font-size: 0.86rem;
  margin-left: -26px;
}

.audio-desc-component-list {
  overflow-y: auto;
  overscroll-behavior-y: contain;
  min-height: 0vh;
  max-height: 45vh;
  margin-bottom: 1rem;
}

.app {
  font-family: sans-serif;
  text-align: center;
  color: white;
  font-family: 'Montserrat';
}

.dialog-timestamps-div {
  float: left;
  /* cursor: ew-resize; */
  position: absolute;
  background-color: #64b5f6;
}

.green-border {
  border: 3px solid green;
}
