@import '../css/index.css';
.component {
  background-color: rgb(80, 80, 80);
  margin-right: 1.3%;
  overflow-y: scroll;
}

.ad-title {
  font-size: large;
  font-weight: 600;
  cursor: pointer;
}

.ad-title-input {
  margin-top: -16px;
  margin-left: 5px;
}

.text-size {
  font-size: large;
}

.nudge-btns-div {
  background-color: #3f51b5;
  border: solid 1px;
  border-radius: 10px;
  height: 30px;
  padding-right: 25px;
  padding-left: 25px;
  box-shadow: 0px 0px 4px 1px rgb(175, 184, 182);
}
.nudge-btns-div:hover {
  box-shadow: 0px 1px 4px 2px rgb(180, 180, 179),
    0px 1px 2px 2px rgb(151, 147, 147);
}
.nudge-icons {
  font-size: 15px;
  cursor: pointer;
}
.fa-chevron-down {
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 40px;
}
.fa-chevron-up {
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 40px;
}

.component-timeline-div {
  height: 20px;
  background-color: white;
  outline: 1.8px #e4a48b solid;
  border-radius: 10px;
  position: relative;
  margin-left: -1.93px;
}

.inline-bg {
  background-color: var(--inline-color);
}
.extended-bg {
  background-color: var(--extended-color);
}
.play-pause-bg {
  background-color: var(--success-color);
}

.inline-extended-radio {
  font-weight: 500;
  border: 1.8px solid;
  border-radius: 10px;
}
.inline-extended-label {
  margin-bottom: 0;
}

/* draggable component - div - dialog timeline */
.ad-draggable-div {
  position: relative;
}
.ad-timestamp-div {
  float: left;
  cursor: ew-resize;
  position: absolute;
  background-color: #64b5f6;
}

/* new AD component */
.close-icon {
  cursor: pointer;
}
.close-icon:hover {
  color: red;
}

.component {
  background-color: rgb(80, 80, 80);
  margin-right: 1.3%;
  overflow-y: auto;
  max-height: 500px;
}

.inline-extended-label {
  margin-bottom: 0;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.edit-time-div {
  background-color: #444;
  padding: 0.25rem;
  border-radius: 0.25rem;
}

.ydx-input {
  background-color: #333;
  color: white;
  border: 1px solid #555;
}

.ydx-input:focus {
  background-color: #444;
  color: white;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-group {
  box-shadow: none;
  font-size: 1.2rem;
}

.description-textarea {
  resize: vertical;
  min-height: 100px;
}
