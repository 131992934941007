.video-page {
  .spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: 0.2em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
    vertical-align: -0.125em;
  }

  @keyframes spinner-border {
    to { transform: rotate(360deg); }
  }

  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }

  .me-2 {
    margin-right: 0.5rem;
  }

  .ai-request-button {
    &.processing {
      background-color: #757575;
      cursor: not-allowed;
    }

    &.unavailable {
      background-color: #9e9e9e;
      cursor: not-allowed;
    }

    &.requested {
      background-color: #795548;
      cursor: not-allowed;
    }

    .spinner-border {
      color: #ffffff;
    }
  }

  .description-buttons {
    margin-top: 8px;

    button {
      margin-bottom: 8px;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  background-color: #eee;
  color: #fff;
  min-height: calc(100vh - 54px); // Modern browsers support calc without prefixes

  @media screen and (min-width: 601px) {
    // padding-top: 16px;
    // background-color: #3B3B3B;
  }

  .video-page-main {
    margin: 0;
    padding: 0;
    width: 100%;
    @media screen and (min-width: 601px) {
      margin: 0 auto;
    }
    .video-area {
      background-color: #000;
      padding-bottom: 16px;
      .video {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 440px;
        @media screen and (min-width: 601px) {
          width: 640px;
          height: 414px;
          margin: 0 auto;
        }
      }
      .video-controls {
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 88px;
        margin: 0 auto;
        @media screen and (min-width: 601px) {
          width: 640px;
          height: 68px;
        }
        .play-button,
        .pause-button {
          display: block;
          border: none;
          outline: none;
          background-color: rgba(0, 0, 0, 0);
          color: white;
          font-size: 18px;
          margin-left: 23px;
        }
      }
      .audio-ducking-container {
        width: 100%;
        position: relative;
        @media screen and (min-width: 601px) {
          bottom: 0px;
          width: 640px;
        }
      }
      .video-timeline {
        width: 100%;
        padding-top: 6px;
        margin-top: 15px;
        position: relative;
        @media screen and (min-width: 601px) {
          bottom: 0px;
          width: 640px;
        }
      }
    }
    .video-info {
      margin-top: 4px;
      .describers {
        h3 {
          position: relative;
          left: 3px;
        }
      }
    }
  }

  .feedback-success,
  .rating-success {
    display: none;
    position: fixed;
    background-color: #fff;
    color: #3f51b5;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); // Combine both transforms
    padding: 20px;
    outline: 1px solid #3f51b5;
  }

  .w3-card-2 {
    background-color: #fff;
    color: #000;
    margin: 4px;
    padding: 8px;
  }
}