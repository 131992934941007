#rating-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);

  #rating-popup-contents {
    position: fixed;
    z-index: 9999;
    width: 350px;
    height: 200px;
    top: calc(50% - 100px);
    left: calc(50% - 175px);
    border: 1px solid #3f51b5;
    background-color: white;

    a {
      color: #fff;
    }

    i {
      position: absolute;
      top: 4px;
      right: 4px;
      font-size: 20px;
      cursor: pointer;
    }

    h2 {
      margin: 0;
      padding: 8px 0;
      background-color: #3f51b5;
      text-align: center;
    }

    p {
      margin: 16px;
      color: grey;
      font-size: 14px;
    }

    .rating {
      width: 100%;
      direction: rtl;
      text-align: center;
      font-size: 24px;
    }

    .rating > button {
      color: grey;
      display: inline-block;
      position: relative;
      padding: 0 1px;
      font-size: 24px;
      width: 1.1em;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      transition: color 0.2s;

      &.filled {
        color: gold;
      }

      &:hover,
      &:hover ~ button {
        color: gold;
      }
    }
  }
}
