#search-bar {
  // position: relative;
  top: 11px;
  padding-left: 0px;
  @media screen and (min-width: 601px) {
    padding-left: 20px; /* 40px -> 20px */
  }
  input {
    outline: none;
    font-size: 16px;
    border-radius: 0px;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    width: 140px; /* 182px -> 140px */
    height: 35px;
    @media (min-width: 900px) {
      /* new */
      width: 140px; /* new */
    }
    @media (min-width: 1050px) {
      /* new */
      width: 200px; /* new */
    }
    @media screen and (min-width: 1200px) {
      /* 601px -> 1200px */
      width: 260px; /* 280px -> 260px */
    }
  }
}
body {
  position: relative;
}

#search-bar {
  position: absolute;

  .suggestions-dropdown {
    position: relative;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    .suggestion-item {
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #e6e6e6;
      }

      &.selected {
        background-color: #d9d9d9;
      }
    }
  }
}
