@import '../css/index.css';
.edit-component {
  background-color: #7a7979;
  margin-top: 0.16%;
  margin-right: 2.5%;
  margin-left: 1.3%;
  border-radius: 5px;
}

.save-desc-btn {
  background-color: var(--success-color);
}

.description-textarea {
  resize: none;
  min-width: 520px;
  max-width: 560px;
  font-size: 1.25rem;
}

.play-pause-icons {
  font-size: smaller;
}

.fa-stop {
  font-size: x-small;
}

.edit-time-div {
  background-color: white;
  padding: 3px;
  border: solid white 1px;
  border-radius: 10px;
  margin-bottom: 8px;
}

.edit-component-title-div {
  margin-right: 3.3rem;
}

.description-section {
  width: 65%;
}

.vertical-divider-div {
  background-color: wheat;
  width: 2px;
  height: 95px;
}

.disabled-btn {
  opacity: 0.45;
  cursor: not-allowed !important;
}

/* Chrome, Safari, Edge, Opera */
.ydx-input::-webkit-outer-spin-button,
.ydx-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  outline: none;
  border-radius: 5px;
}

/* Firefox */
.ydx-input[type='number'] {
  -moz-appearance: textfield;
  outline: none;
  border-radius: 5px;
}
