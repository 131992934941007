.avatar-button {
  border: none;
  background: none;
  outline: none;
  padding: 0;
}
.avatar-img {
  border-radius: 50%;
  cursor: pointer;
}
