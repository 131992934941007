.wish-list {
  /* Firefox */
  min-height: -moz-calc(100vh - 54px);
  /* WebKit */
  min-height: -webkit-calc(100vh - 54px);
  /* Opera */
  min-height: calc(100vh - 54px);
  /* Standard */
  section {
    margin-bottom: 40px;
  }

  .login-prompt {
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
    margin: 20px 0;

    p {
      margin-bottom: 15px;
      font-size: 18px;
    }

    .login-button {
      background-color: #3f51b5;
      color: white;
      border: none;
      padding: 10px 20px;
      font-size: 16px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #303f9f;
      }
    }
  }
}

.most-requested-title {
  font-size: 22px;
  font-weight: 400;
  font-family: 'Verdana', sans-serif;
}

.wishlist-video-card {
  width: 180px;
  @media screen and (min-width: 601px) {
    width: 194px;
  }
}

.wishlist-video-row {
  display: flex;
  flex-direction: row;
}

.table-container {
  width: 376px;
  padding: 0 8px 16px 8px;
  margin: 0 auto;
  @media screen and (max-width: 601px) {
    width: 97%;
    padding: 0 0 8px 0;
  }
  @media screen and (min-width: 601px) {
    width: 90%;
    padding: 0 0 16px 0;
  }
  @media screen and (min-width: 1020px) {
    width: 1020px;
  }
}

.search-container {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  .search-input {
    width: 100%;
    padding: 6px;
  }
  .category-label {
    margin-top: 10px;
  }
  .category-select {
    width: 100%;
  }
}

.search-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  .search-button {
    font-size: 16px;
    @media (min-width: 900px) {
      width: 140px;
    }
    @media (min-width: 1050px) {
      width: 200px;
    }
    @media screen and (min-width: 1200px) {
      width: 260px;
    }
  }
}

.prev-wishlist-icon,
.next-wishlist-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 4%;
  height: 25%;
  background-color: rgb(62, 111, 202);
  border: none;
  color: white;
  cursor: pointer;
  margin: 15px;
  font-size: 25px;
}

.prev-wishlist-icon {
  left: -5%;
}

.next-wishlist-icon {
  right: -5%;
}

@media (min-width: 768px) and (max-width: 1150px) {
  .prev-wishlist-icon {
    left: 2%;
  }

  .next-wishlist-icon {
    right: 2%;
  }
}

@media (min-width: 1150px) and (max-width: 1250px) {
  .prev-wishlist-icon {
    left: 4%;
  }

  .next-wishlist-icon {
    right: 4%;
  }
}

@media (min-width: 1300px) {
  .prev-wishlist-icon {
    left: -10%;
  }

  .next-wishlist-icon {
    right: -10%;
  }
}

:root {
  --items-per-page: 4;
}

@media (min-width: 1024px) {
  :root {
    --items-per-page: 4;
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  :root {
    --items-per-page: 3;
  }
}

@media (max-width: 767px) {
  :root {
    --items-per-page: 2;
  }
}

@media (max-width: 700px) {
  :root {
    --items-per-page: 1;
  }
}

.custom-carousel {
  position: relative;
  overflow: hidden;
  padding: 0 40px;
}

.no-videos-message {
  text-align: center;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  margin: 20px 0;
  font-size: 18px;
  color: #333;
  font-family: 'Verdana', sans-serif;
}

