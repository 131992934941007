.app {
  text-align: center;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.time {
  font-size: 1.5rem;
  padding: 2rem;
}

.button {
  padding: 0.6rem 1.5rem;
  margin: 0.4rem;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8rem;
  border-style: groove;
}

.button:focus {
  outline-width: 0;
}

.button-primary:hover {
  background-color: #2641d4;
  border: 1px solid #1b1f2b;
}

.button-primary-active {
  background-color: #3151ff;
  border: 1px solid #152684;
  color: white;
}

.button-primary-inactive {
  background-color: #3151ff;
  border: 1px solid #152684;
  color: white;
}

.button-danger {
  background-color: rgb(236, 123, 123);
}
